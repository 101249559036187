import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchMenu = createAsyncThunk('fetch/menu', async (arg, { rejectWithValue }) => {
  const result = {};
  try {
    let slug, key;
    if (typeof arg === 'string') slug = key = arg;
    else {
      slug = arg.slug;
      key = arg.key;
    }
    if (!(slug && key)) return rejectWithValue('Invalid argument');
    const response = await axios.get(`/menu/${slug}/`);
    result[key] = response.data.items;
  } catch (_) {}
  return result;
});

const menuSlice = createSlice({
  name: 'menu',
  initialState: {},
  reducers: {
    update: (state, action) => Object.assign({}, state, action.payload),
  },
  extraReducers: {
    [fetchMenu.fulfilled]: (state, action) => Object.assign({}, state, action.payload),
  },
});

export default menuSlice;
