import React from 'react';

// Root Include
const Root = React.lazy(() => import('./pages/Home/index'));

// Industry & Solution
const PageDetail = React.lazy(() => import('./pages/Pages/PageDetails'));

//Blog
const PageBlog = React.lazy(() => import('./pages/Pages/Blog/PageBlog'));
const PageBlogDetail = React.lazy(() => import('./pages/Pages/Blog/PageBlogDetail'));

//Contact
const PageContactOne = React.lazy(() => import('./pages/Pages/PageContactOne'));

//Special
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

const routes = [
  //Index root
  {
    path: '/',
    component: Root,
    exact: true,
  },

  //Page Blog
  {
    path: '/blog',
    component: PageBlog,
  },

  //Page Contact
  {
    path: '/contact',
    component: PageContactOne,
  },

  // Special Pages
  {
    path: '/page-error',
    component: PageError,
    isWithoutLayout: true,
  },

  // Blogs Detail
  {
    path: '/blog/:slug',
    component: PageBlogDetail,
  },

  // Industry Detail
  {
    path: '/:slug',
    component: PageDetail,
  },

  // Page Not Found
  {
    component: PageError,
    isWithoutLayout: true,
    exact: false,
  },
];

export default routes;
