import FeatherIcon from 'feather-icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';

import logodark from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';

class Footer extends Component {
  get prefs() {
    return this.props.prefs;
  }

  constructor(props) {
    super(props);
    this.state = {
      socialIcons: [],
    };
  }

  componentDidMount() {
    this.updatePrefs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.prefs !== this.prefs) this.updatePrefs();
  }

  updatePrefs() {
    const prefs = this.prefs;

    const socialIcons = [];
    if (prefs.facebook_url) socialIcons.push({ icon: 'facebook', title: 'Facebook', url: prefs.facebook_url });
    // if (prefs.instagram_url) socialIcons.push({ icon: 'instagram', url: prefs.instagram_url });
    // if (prefs.twitter_url) socialIcons.push({ icon: 'twitter', url: prefs.twitter_url });
    if (prefs.youtube_url) socialIcons.push({ icon: 'youtube', title: 'YouTube', url: prefs.youtube_url });
    if (prefs.github_url) socialIcons.push({ icon: 'github', title: 'Github', url: prefs.github_url });
    if (prefs.contact_whatsapp)
      socialIcons.push({ icon: 'message-circle', title: 'WhatsApp', url: this.whatsappUri(prefs.contact_whatsapp) });

    this.setState({ prefs, socialIcons });
  }

  normalizePhoneNumber(num = '') {
    return String(num).replace(/\D/g, '');
  }

  whatsappUri(num) {
    return (
      'https://wa.me/' +
      this.normalizePhoneNumber(num) +
      '?text=Saya memiliki pertanyaan mengenai bisnis yang ditawarkan'
    );
  }

  onSubscribe = event => {
    event.preventDefault();
    // TODO: Validate and submit subscribtion email to server
    console.log(event);
  };

  render() {
    return (
      <React.Fragment>
        {/* Shape Dark */}
        <div className="position-relative">
          <div className="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <footer className={this.props.isLight ? 'footer bg-light' : 'footer'}>
          <Container>
            <Row>
              <Col lg="5" xs="12" className="mb-0 mb-md-4 pb-0 pb-md-2" name="footercolumn">
                <Link to="/" className="logo-footer">
                  <img src={this.props.isLight ? logodark : logolight} height="24" alt="" />
                </Link>
                <p className="mt-4">{this.prefs.contact_address}</p>
                <p>
                  Telepon :
                  {this.prefs.contact_phone &&
                    this.prefs.contact_phone.split(/\s?,\s?/).map((num, idx) => (
                      <React.Fragment key={idx}>
                        {idx > 0 && ', '}
                        <a
                          className={this.props.isLight ? 'text-muted ml-2' : 'text-foot ml-2'}
                          href={'tel:' + this.normalizePhoneNumber(num)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {num}
                        </a>
                      </React.Fragment>
                    ))}
                </p>
                <p>
                  WhatsApp :
                  <a
                    className={this.props.isLight ? 'text-muted ml-2' : 'text-foot ml-2'}
                    href={this.whatsappUri(this.prefs.contact_whatsapp)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {this.prefs.contact_whatsapp}
                  </a>
                </p>
                <p>
                  Email :
                  <a
                    className={this.props.isLight ? 'text-muted ml-2' : 'text-foot ml-2'}
                    href={'mailto:' + this.prefs.contact_email + '?subject=Pertanyaan Produk Karomap'}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {this.prefs.contact_email}
                  </a>
                </p>

                <ul className="list-unstyled social-icon social mb-0 mt-4">
                  {this.state.socialIcons.map((socialIcon, key) => (
                    <li className="list-inline-item mr-1" key={key}>
                      <a
                        href={socialIcon.url}
                        title={socialIcon.title}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="rounded"
                      >
                        <FeatherIcon icon={socialIcon.icon} className="fea icon-sm fea-social" />
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>

              <Col lg="3" md="4" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0" name="footercolumn">
                <h4 className={this.props.isLight ? 'text-dark footer-head' : 'text-light footer-head'}>
                  Bidang Industri
                </h4>
                <ul className="list-unstyled footer-list mt-4">
                  {this.props.footers
                    .filter(footer => footer.order < 5)
                    .map((footer, key) => (
                      <li key={key}>
                        <Link to={footer.url} className={this.props.isLight ? 'text-muted' : 'text-foot'}>
                          <i className="mdi mdi-chevron-right mr-1"></i> {footer.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </Col>

              {/* <Col lg="2" md="4" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0" name="footercolumn">
                <h4 className={this.props.isLight ? 'text-dark footer-head' : 'text-light footer-head'}>Links</h4>
                <ul className="list-unstyled footer-list mt-4">
                  {this.state.footers
                    .filter(footer => footer.order >= 5)
                    .map((footer, key) => (
                      <li key={key}>
                        <Link to={footer.url} className={this.props.isLight ? 'text-muted' : 'text-foot'}>
                          <i className="mdi mdi-chevron-right mr-1"></i> {footer.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </Col> */}
              <Col lg="4" md="4" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0" name="footercolumn">
                <h4 className={this.props.isLight ? 'text-dark footer-head' : 'text-light footer-head'}>Surat Kabar</h4>
                <p className="mt-4">Mendaftar untuk berlangganan informasi terbaru dari kami.</p>
                <Form onSubmit={this.onSubscribe}>
                  <Row>
                    <Col lg="12">
                      <div
                        className={
                          this.props.isLight
                            ? 'foot-subscribe foot-white form-group position-relative'
                            : 'foot-subscribe form-group position-relative'
                        }
                      >
                        <Label className={this.props.isLight ? 'text-muted' : ''}>
                          Alamat email <span className="text-danger">*</span>
                        </Label>
                        <FeatherIcon icon="mail" className="fea icon-sm icons" />
                        <Input
                          type="email"
                          name="email"
                          id="emailsubscribe"
                          className={
                            this.props.isLight
                              ? 'form-control bg-light border pl-5 rounded'
                              : 'form-control pl-5 rounded'
                          }
                          placeholder="Masukkan alamat email"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <Button type="submit" className="btn btn-soft-primary btn-block">
                        Berlangganan
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </footer>
        <footer className="footer footer-bar">
          <Container className="text-center">
            <Row className="align-items-center">
              <Col sm="12">
                <div className="text-sm-left">
                  <p className="mb-0">
                    &copy; 2020 <Link to="/">{this.prefs.site_author}</Link>. All rights reserved.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default connect(state => ({ prefs: state.prefs, footers: state.menu.footer || [] }))(Footer);
