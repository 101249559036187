import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.interceptors.request.use(
  request => {
    return request;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

if (process.env.NODE_ENV === 'production') ReactGA.initialize('UA-124197730-1');
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.register();
