import { combineReducers } from 'redux';

import menuSlice from './menu';
import postSlice from './post';
import preferenceSlice from './preference';

export default combineReducers({
  prefs: preferenceSlice.reducer,
  menu: menuSlice.reducer,
  post: postSlice.reducer,
});
