import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPostBySlug = createAsyncThunk('fetch/postBySlug', async (slug, { rejectWithValue, getState }) => {
  try {
    const posts = getState().post;
    if (posts.bySlug[slug]) return posts.bySlug[slug];
    const res = await axios.get(`/post/${slug}/`);
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchPostsByType = createAsyncThunk('fetch/postsByType', async (params, { rejectWithValue, getState }) => {
  try {
    if (!params.type) return rejectWithValue('Invalid argument. Parameter "type" is required.');
    const posts = getState().post;
    if (posts.byType[params.type]) return posts.byType[params.type];
    if (!params.limit) params.limit = 0;
    const res = await axios.get('/post/', { params });
    return res.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export function firstPostByType(state, type) {
  const data = state.post.byType[type];
  let result = null;
  if (data) {
    if (Array.isArray(data.results)) result = data.results[0];
    else if (Array.isArray(data)) result = data[0];
  }
  return result;
}

export function postDescription(post, forceBody = false) {
  if (!post) return '';
  if (post.description && !forceBody) return post.description;
  const tmpEl = document.createElement('div');
  tmpEl.innerHTML = post.body;
  return tmpEl.textContent.slice(0, 200);
}

const postSlice = createSlice({
  name: 'posts',
  initialState: {
    byType: {},
    bySlug: {},
  },
  reducers: {},
  extraReducers: {
    [fetchPostBySlug.fulfilled]: (state, action) => {
      state.bySlug[action.meta.arg] = action.payload;
    },
    [fetchPostsByType.fulfilled]: (state, action) => {
      state.byType[action.meta.arg.type] = action.payload;
    },
  },
});

export default postSlice;
