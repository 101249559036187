import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialPrefs = {
  site_author: process.env.REACT_APP_SITE_AUTHOR,
  site_description: process.env.REACT_APP_SITE_DESCRIPTION,
  site_name: process.env.REACT_APP_SITE_NAME,
  site_tagline: process.env.REACT_APP_SITE_TAGLINE,
};

export const fetchPreferences = createAsyncThunk('fetch/prefs', async (arg, thunkApi) => {
  const prefs = {};
  try {
    const response = await axios.get('/preferences/');
    for (const pref of response.data) {
      let value = pref.value;
      if (['file', 'files'].indexOf(pref.type) !== -1)
        try {
          value = JSON.parse(value);
          if (pref.type === 'file' && Array.isArray(value)) value = value[0];
        } catch (_) {}
      prefs[pref.key] = value;
    }
  } catch (err) {
    thunkApi.rejectWithValue(err);
  }
  return prefs;
});

const preferenceSlice = createSlice({
  name: 'preference',
  initialState: initialPrefs,
  reducers: {
    update: (state, action) => Object.assign({}, state, action.payload),
  },
  extraReducers: {
    [fetchPreferences.fulfilled]: (state, action) => Object.assign({}, state, action.payload),
  },
});

export default preferenceSlice;
