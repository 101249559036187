import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Layout from './components/Layout';
// Include Routes
import routes from './routes';

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent {...this.props}></WrappedComponent>
        </Layout>
      );
    }
  };
}

class Container extends Component {
  render() {
    return (
      <TransitionGroup className="transition-group">
        <CSSTransition key={this.props.location.key} timeout={{ enter: 300, exit: 300 }} classNames={'fade'}>
          <div className="route-section">
            <Switch>
              {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                ) : (
                  <Route path={route.path} exact component={withLayout(route.component)} key={idx} />
                )
              )}
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(Container);
