// Import Css
import './Apps.scss';

import React, { Component, Suspense } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { fetchMenu } from './app/reducers/menu';
import { fetchPreferences } from './app/reducers/preference';
import Loader from './components/Shared/Loader';
import RouteEventWrapper from './components/Shared/RouteEventWrapper';
import Container from './Container';

class App extends Component {
  get prefs() {
    return this.props.prefs;
  }

  componentDidMount() {
    this.props.dispatch(fetchPreferences());
    this.props.dispatch(fetchMenu('main'));
    this.props.dispatch(fetchMenu({ slug: 'main-footers', key: 'footer' }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (process.env.NODE_ENV === 'production') ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta name="author" content={this.prefs.site_author} />
        </Helmet>
        <Router>
          <Suspense fallback={Loader()}>
            <RouteEventWrapper>
              <Container />
            </RouteEventWrapper>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default connect(state => ({ prefs: state.prefs }))(App);
