import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

//Import images
import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';

class Topbar extends Component {
  get isDark() {
    const topnavEl = document.getElementById('topnav');
    return window.innerWidth > 768 && topnavEl && topnavEl.classList.contains('.nav-dark-bg');
  }

  constructor(props) {
    super(props);
    this.state = {
      navLinks: this.props.navLinks || [],
      logo: logoDark,
      isOpen: false,
    };
    this.checkDarkListener = this.checkDark.bind(this);
    this.checkDarkDebounced = debounce(this.checkDarkListener, 100);
  }

  componentDidMount() {
    this.checkActiveItem();

    window.addEventListener('scroll', this.checkDarkDebounced);
    window.addEventListener('resize', this.checkDarkListener);
    window.addEventListener('load', () => {
      this.checkDark();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.navLinks !== this.props.navLinks && this.props.navLinks)
      this.setState({ navLinks: this.props.navLinks });
    this.checkActiveItem();
    this.checkDark();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkDarkDebounced);
    window.removeEventListener('resize', this.checkDarkListener);
  }

  toggleLine = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  checkActiveItem() {
    const ul = document.getElementById('top-menu');
    const items = ul.querySelectorAll('a');

    // Clear active items
    ul.querySelectorAll('.active').forEach(el => el.classList.remove('active'));

    items.forEach(item => {
      const pathname = item.href.endsWith('#') ? '#' : item.pathname;
      if (this.props.location.pathname === pathname) {
        let parent = item.parentElement;
        while (parent) {
          parent.classList.add('active');
          parent = parent.parentElement;
          if (parent === ul) break;
        }
      }
    });
  }

  checkDark() {
    const logo = this.isDark ? logoLight : logoDark;
    if (logo !== this.state.logo) this.setState({ logo });
  }

  openBlock(level2_id) {
    const navLinks = this.state.navLinks.map(tmpLink => {
      //Match level 2 id
      tmpLink = Object.assign({}, tmpLink);
      if (tmpLink.id === level2_id) tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu;
      return tmpLink;
    });
    this.setState({ navLinks });
  }

  openNestedBlock(level2_id, level3_id) {
    const navLinks = this.state.navLinks.map(tmpLink => {
      tmpLink = Object.assign({}, tmpLink);
      if (tmpLink.id === level2_id) {
        tmpLink.sub_items = tmpLink.sub_items.map(tmpChild => {
          //if level1 id is matched then match level 3 id
          tmpChild = Object.assign({}, tmpChild);
          if (tmpChild.id === level3_id) tmpChild.isOpenNestedSubMenu = !tmpChild.isOpenNestedSubMenu;
          else tmpChild.isOpenNestedSubMenu = false;
          return tmpChild;
        });
      }
      return tmpLink;
    });
    this.setState({ navLinks });
  }

  absoluteUri(uri) {
    if (/^(https?:\/)?\//.test(uri)) return uri;
    return '/' + uri;
  }

  render() {
    return (
      <React.Fragment>
        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              <Link className="logo" to="/">
                <img src={this.state.logo} id="brandLogo" height="26" alt="" />
              </Link>
            </div>
            <div className="buy-button d-none d-lg-block">
              {/* <Link to="/contact" className="btn btn-primary" id="buyButton" rel="noopener noreferrer">
                Contact Now
              </Link> */}
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <button
                  onClick={this.toggleLine}
                  className={this.state.isOpen ? 'navbar-toggle open' : 'navbar-toggle'}
                  style={{ background: 'inherit' }}
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
            </div>

            <div id="navigation" style={{ display: this.state.isOpen ? 'block' : 'none' }}>
              <ul className="navigation-menu" id="top-menu">
                {this.state.navLinks.map((navLink, key) =>
                  navLink.sub_items.length ? (
                    <li className="has-submenu" key={key}>
                      {/* child item(menu Item) - Level 1 */}
                      <a
                        href={this.absoluteUri(navLink.url)}
                        onClick={event => {
                          event.preventDefault();
                          this.openBlock(navLink.id);
                        }}
                      >
                        {navLink.title}
                      </a>
                      <span className="menu-arrow"></span>
                      {navLink.isMegaMenu ? (
                        // if menu is mega menu(2 columns grid)
                        <ul className={navLink.isOpenSubMenu ? 'submenu megamenu open' : 'submenu megamenu'}>
                          <li>
                            <ul>
                              {navLink.sub_items.map((item, childKey) =>
                                childKey < 5 ? (
                                  <li key={childKey}>
                                    <Link to={this.absoluteUri(item.url)}>{item.title}</Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                          <li>
                            <ul>
                              {navLink.sub_items.map((item, childKey) =>
                                childKey >= 5 && childKey < 10 ? (
                                  <li key={childKey}>
                                    <Link to={this.absoluteUri(item.url)}>{item.title}</Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                          <li>
                            <ul>
                              {navLink.sub_items.map((item, childKey) =>
                                childKey >= 10 ? (
                                  <li key={childKey}>
                                    <Link to={this.absoluteUri(item.url)}>{item.title}</Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        </ul>
                      ) : (
                        // if menu is not mega menu(1grid)
                        <ul className={navLink.isOpenSubMenu ? 'submenu open' : 'submenu'}>
                          {navLink.sub_items.map((childArray, childKey) =>
                            childArray.nestedChild ? (
                              // sub menu item - Level 2
                              <li className="has-submenu" key={childKey}>
                                <a
                                  href={this.absoluteUri(childArray.url)}
                                  onClick={event => {
                                    event.preventDefault();
                                    this.openNestedBlock(navLink.id, childArray.id);
                                  }}
                                >
                                  {childArray.title}
                                </a>
                                <span className="submenu-arrow"></span>
                                <ul className={childArray.isOpenNestedSubMenu ? 'submenu open' : 'submenu'}>
                                  {childArray.nestedChild.map((nestedChildArray, nestedKey) => (
                                    // nested sub menu item - Level 3
                                    <li key={nestedKey}>
                                      <Link to={this.absoluteUri(nestedChildArray.url)}>{nestedChildArray.title}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ) : (
                              <li key={childKey}>
                                <Link to={this.absoluteUri(childArray.url)}>{childArray.title}</Link>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li key={key}>
                      <Link to={this.absoluteUri(navLink.url)}>{navLink.title}</Link>
                    </li>
                  )
                )}
              </ul>
              <div className="buy-menu-btn d-none">
                {/* <Link to="/contact" className="btn btn-primary" rel="noopener noreferrer">
                  Contact Now
                </Link> */}
              </div>
            </div>
          </Container>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({ navLinks: state.menu.main }))(withRouter(Topbar));
