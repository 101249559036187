import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

class RouteEventWrapper extends React.Component {
  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (process.env.NODE_ENV === 'production') ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(RouteEventWrapper);
