import FeatherIcon from 'feather-icons-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';

import Footer from './Footer';
import Topbar from './Topbar';

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Topbar />

        {this.props.children}

        <Footer isLight={false} />

        <ScrollUpButton
          ContainerClassName="scroll-up-container"
          TransitionClassName="scroll-up-transition"
          style={{ height: 36, width: 36, zIndex: 1000 }}
        >
          <FeatherIcon icon="arrow-up" className="icons" />
        </ScrollUpButton>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
